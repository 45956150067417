import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import Big from 'big.js';
import { tryParseInt } from '../../utils/tryParseInt';
import { spotify } from '../../components/musicServices/services/Spotify';
import { tidal } from '../../components/musicServices/services/Tidal';
import { napster } from '../../components/musicServices/services/Napster';
import { appleMusic } from '../../components/musicServices/services/AppleMusic';
import { deezer } from '../../components/musicServices/services/Deezer';
import { amazonMusic } from '../../components/musicServices/services/AmazonMusic';
import { pandora } from '../../components/musicServices/services/Pandora';
import { soundcloud } from '../../components/musicServices/services/SoundCloud';
import { youtubeMusic } from '../../components/musicServices/services/YouTubeMusic';

const initialNoStreams = 10000;

const averagePayPerStream = [
  {
    service: spotify,
    payPerStreamInUSD: Big('0.00437')
  },
  {
    service: tidal,
    payPerStreamInUSD: Big('0.01284')
  },
  {
    service: napster,
    payPerStreamInUSD: Big('0.019')
  },
  {
    service: appleMusic,
    payPerStreamInUSD: Big('0.00783')
  },
  {
    service: deezer,
    payPerStreamInUSD: Big('0.0064')
  },
  {
    service: amazonMusic,
    payPerStreamInUSD: Big('0.00402')
  },
  {
    service: pandora,
    payPerStreamInUSD: Big('0.00133')
  },
  {
    service: youtubeMusic,
    payPerStreamInUSD: Big('0.002')
  },
  {
    service: soundcloud,
    payPerStreamInUSD: Big('0.0032')
  }
];

export const BlogMusicStreamingCalculatorMusicServiceCalculated: React.FC<{
  entry: (typeof averagePayPerStream)[number];
  numberOfStreams: number;
}> = React.memo(({ entry, numberOfStreams }) => {
  const intl = useIntl();
  const totalPay = entry.payPerStreamInUSD.times(numberOfStreams);
  return (
    <tr key={entry.service.id} style={{ border: 0 }}>
      <td
        style={{
          width: '50%',
          border: 0,
          textAlign: 'right',
          verticalAlign: 'middle',
          paddingTop: 0,
          paddingBottom: 0
        }}
      >
        <div className="d-flex align-items-end flex-column">
          <img style={{ margin: 0 }} src={entry.service.logoColor.dark} alt={entry.service.name} />
        </div>
      </td>
      <td style={{ width: '50%', border: 0, textAlign: 'left', color: '#fff', verticalAlign: 'middle' }}>
        <strong>
          {intl.formatNumber(totalPay.toNumber(), {
            currency: 'USD',
            style: 'currency',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
          })}
        </strong>
      </td>
    </tr>
  );
});

export const BlogMusicStreamingCalculator: React.FC = React.memo(() => {
  const intl = useIntl();

  const [numberOfStreams, setNumberOfStreams] = useState<string>(`${initialNoStreams}`);
  const [numberOfStreamsParsed, setNumberOfStreamsParsed] = useState<number>(initialNoStreams);
  const onStreamsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedNumber = tryParseInt(e.target.value) ?? 0;
    setNumberOfStreams(e.target.value);
    setNumberOfStreamsParsed(parsedNumber);
  }, []);
  return (
    <div className="blog-music-streaming-calculator d-flex flex-column align-items-center justify-content-center p-5">
      <h3>
        {intl.formatMessage({
          defaultMessage: 'Music Streaming Calculator',
          id: 'web.blog.music-streaming-calculator.title'
        })}
      </h3>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="d-flex justify-content-center flex-column">
            <FormGroup className="pt-3">
              <Label for="numberOfStreams">
                {intl.formatMessage({
                  defaultMessage: 'Enter number of streams:',
                  id: 'web.blog.music-streaming-calculator.input'
                })}
              </Label>
              <Input
                value={numberOfStreams}
                onChange={onStreamsChange}
                id={numberOfStreams}
                style={{ maxWidth: 300 }}
                className="mx-auto"
              />
            </FormGroup>
            <small className="opacity-50 pb-3">
              {intl.formatMessage({
                defaultMessage: 'This calculator only provides an estimate',
                id: 'web.blog.music-streaming-calculator.estimate'
              })}
            </small>
            <div className="mt-4">
              {intl.formatMessage(
                {
                  defaultMessage: 'For {numberOfStreams} streams,{br} you could earn on average:',
                  id: 'web.blog.music-streaming-calculator.description'
                },
                { br: <br />, numberOfStreams: <strong>{intl.formatNumber(numberOfStreamsParsed)}</strong> }
              )}
            </div>
          </Col>

          <Col xs={12} md={6} className="pt-3">
            <Table striped style={{ border: 0 }} className="my-3">
              {averagePayPerStream.map((entry) => (
                <BlogMusicStreamingCalculatorMusicServiceCalculated
                  key={entry.service.id}
                  entry={entry}
                  numberOfStreams={numberOfStreamsParsed}
                />
              ))}
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
});
