/* eslint-disable react/no-danger */
import React, { useCallback, useEffect } from 'react';
import { RawIntlProvider, useIntl } from 'react-intl';
import { createRoot } from 'react-dom/client';
import { useStore } from 'react-redux';
import { useRouter } from 'next/router';
import { Tweet } from 'react-twitter-widgets';
import YouTube from 'react-youtube'; // import ReactMarkdown from 'react-markdown';
import classNames from 'classnames'; // const imageCMSRegex = /!\[(.+?)\]\(https:\/\/cms-fym\.s3\.nl-ams.scw\.cloud\/(.+?)\)/gm;
import { BlogCTAContent } from '../BlogCTAContent';
import { trackBlogCTA } from '../../../components/googleAnalytics';
import { AppleMusicAd } from '../../../components/AppleMusicAd';
import { BlogCTASmartLinksContent } from '../BlogCTASmartLinksContent';
import { BlogMusicStreamingCalculator } from '../BlogMusicStreamingCalculator';
import { fixBlogLinks } from './fixBlogLinks';

const imageCMSRegex = /!\[(.*?)]\(https:\/\/cms-fym\.s3\.nl-ams.scw\.cloud\/(.+?)(| ".+")\)/gm;
export const GraphCMSRichContent: React.FC<{
  content: string;
  className?: string;
}> = ({ content, className }) => {
  const intl = useIntl();
  const store = useStore();
  const router = useRouter();
  const { locale } = router;

  let preparedContent = fixBlogLinks(content);
  preparedContent = preparedContent.replace(
    imageCMSRegex,
    '![$1](https://cms-fym.imgix.net/$2?auto=compress,format&fit=fillmax&ch=Save-Data&w=1200&max-h=1200$3)'
  );
  preparedContent = preparedContent.replace(/\[CTA]/g, `\n<div class="blog-cta"></div>\n`);
  preparedContent = preparedContent.replace(/\[CTA_SML]/g, `\n<div class="blog-cta-sml"></div>\n`);
  preparedContent = preparedContent.replace(
    /\[MUSIC_STREAMING_CALCULATOR]/g,
    `\n<div class="blog-music-streaming-calculator"></div>\n`
  );
  preparedContent = preparedContent.replace(/\[ANCHOR:(.+?)]/g, `\n<a id="$1"></a>\n`);
  preparedContent = preparedContent.replace(
    /\[TWITTER:(.+?)]/g,
    `\n<div class="twitter-embed" data-tweet-id="$1"></div>\n`
  );
  preparedContent = preparedContent.replace(
    /\[YOUTUBE:(.+?)]/g,
    `\n<div class="youtube-embed" data-video-id="$1"></div>\n`
  );
  preparedContent = preparedContent.replace(/\[APPLEMUSIC_PROMO]/g, `\n<div class="apple-music-promo"></div>\n`);
  preparedContent = preparedContent.replace(/\u00a0/g, ' ');

  const onBlogCTAClicked = useCallback(() => {
    trackBlogCTA({
      path: window.location.href
    });
    router.push(`/download`).catch(console.error);
  }, [router]);

  useEffect(() => {
    window.onBlogCTAClicked = onBlogCTAClicked;
    if (!locale) {
      return;
    }

    const ctaNodes = Array.from(document.getElementsByClassName('blog-cta'));
    if (ctaNodes.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const ctaNode of ctaNodes) {
        createRoot(ctaNode).render(
          <RawIntlProvider value={intl}>
            <BlogCTAContent locale={locale} />
          </RawIntlProvider>
        );
      }
    }

    const ctaSmlNodes = Array.from(document.getElementsByClassName('blog-cta-sml'));
    if (ctaSmlNodes.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const ctaSmlNode of ctaSmlNodes) {
        createRoot(ctaSmlNode).render(
          <RawIntlProvider value={intl}>
            <BlogCTASmartLinksContent locale={locale} />
          </RawIntlProvider>
        );
      }
    }

    const calculatorNodes = Array.from(document.getElementsByClassName('blog-music-streaming-calculator'));
    if (calculatorNodes.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const calculatorNode of calculatorNodes) {
        createRoot(calculatorNode).render(
          <RawIntlProvider value={intl}>
            <BlogMusicStreamingCalculator />
          </RawIntlProvider>
        );
      }
    }

    const twitterNodes = Array.from(document.getElementsByClassName('twitter-embed'));
    if (twitterNodes.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const twitterNode of twitterNodes) {
        const tweetId = twitterNode.getAttribute('data-tweet-id');
        if (tweetId) {
          createRoot(twitterNode).render(
            <RawIntlProvider value={intl}>
              <Tweet tweetId={tweetId} />
            </RawIntlProvider>
          );
        }
      }
    }

    const youtubeNodes = Array.from(document.getElementsByClassName('youtube-embed'));
    if (youtubeNodes.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const youtubeNode of youtubeNodes) {
        const videoId = youtubeNode.getAttribute('data-video-id');
        if (videoId) {
          createRoot(youtubeNode).render(<YouTube videoId={videoId} />);
        }
      }
    }

    const appleMusicPromoNodes = Array.from(document.getElementsByClassName('apple-music-promo'));
    if (appleMusicPromoNodes.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const appleMusicPromoNode of appleMusicPromoNodes) {
        createRoot(appleMusicPromoNode).render(
          <RawIntlProvider value={intl}>
            <AppleMusicAd />
          </RawIntlProvider>
        );
      }
    }
  }, [intl, locale, onBlogCTAClicked, store]);
  return (
    <div
      className={classNames('graphcms-rich-content', className)}
      dangerouslySetInnerHTML={{ __html: preparedContent }}
    />
  );
};
