import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import SMLLogo from '../../../public/statics/logos-sml/logo-full-dark.svg';
import { trackBlogCTASML } from '../../components/googleAnalytics';

export const BlogCTASmartLinksContent: React.FC<{ locale: string }> = React.memo(({ locale }) => {
  const intl = useIntl();
  const onBlogCTAClicked = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      trackBlogCTASML({
        path: window.location.href
      });
      window.open(`https://smartlinksapp.com/${locale}`);
    },
    [locale]
  );
  return (
    <div className="blog-cta-sml d-flex flex-column align-items-center justify-content-center">
      <SMLLogo
        alt={intl.formatMessage({
          id: 'web.logo-sml-alt',
          defaultMessage: 'SmartLinks – Promote your music'
        })}
      />
      <div className="mt-4">
        <strong>
          {intl.formatMessage({
            defaultMessage: 'Promote your music with Smart Links',
            id: 'web.blog.cta-sml.title'
          })}
        </strong>
      </div>
      <div className="mt-2">
        {intl.formatMessage({
          defaultMessage: 'Get more streams across all streaming platforms',
          id: 'web.blog.cta-sml.subtitle'
        })}
      </div>
      <Button className="mt-4 px-5" href={`https://smartlinksapp.com/${locale}`} size="lg" onClick={onBlogCTAClicked}>
        {intl.formatMessage({
          defaultMessage: 'TRY FOR FREE',
          id: 'web.blog.cta-sml.button'
        })}
      </Button>
    </div>
  );
});
